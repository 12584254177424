<template>
	<div class="line">
		<div>
			<van>
				<van-list style="padding:15px 15px 50px 15px;height: 100%;" v-if="goodsList.length != 0">
					<div v-for="(item,index) in goodsList" :key="index" class="goods-item">
						<van-swipe-cell>
							<div class="lists">
								<p>名称：{{item.name}}</p>
								<p>说明:{{item.tip}}</p>
							</div>
							<template #right>
								<van-button square type="primary" text="编辑" @click='detail(item.id)'/>
							    <van-button square type="danger" text="删除" @click='del(item.id)'/>
							  </template>
						</van-swipe-cell>
					</div>
				</van-list>
				<van-empty description="暂无排队" v-else />
			</van>
		</div>
		<button @click="addLine" class="add">添加</button>
		<van-popup v-model:show="add">
			<van-form @submit="onSubmit" class="pass-form">
			  <van-field
			      label="名称"
			      label-width="5rem"
			      size="large"
			      clearable
			      v-model="name"
			      name="name"
			      placeholder="请输入名称"
			      :rules="[{ required:true, message: '请输入名称' }]"
			  />
			  <van-field
			      label="说明"
			      size="large"
			      label-width="5rem"
			      clearable
			      v-model="tip"
			      name="tip"
			      placeholder="请输入说明"
			      :rules="[{ required:true, message: '请输入说明' }]"
			  />
			  <div style="margin-top:2rem;">
			    <button style="width: 100%;height: 40px;background-color: #ee0a24;border: 1px solid #ee0a24;border-radius: 20px;color: #FFFFFF;" native-type="submit">添加</button>
			  </div>
			</van-form>
		</van-popup>
		<van-popup v-model:show="edit">
			<van-form @submit="onSubmit" class="pass-form">
			  <van-field
			      label="名称"
			      label-width="5rem"
			      size="large"
			      clearable
			      v-model="name"
			      name="name"
			      placeholder="请输入名称"
			      :rules="[{ required:true, message: '请输入名称' }]"
			  />
			  <van-field
			      label="说明"
			      size="large"
			      label-width="5rem"
			      clearable
			      v-model="tip"
			      name="tip"
			      placeholder="请输入说明"
			      :rules="[{ required:true, message: '请输入说明' }]"
			  />
			  <div style="margin-top:2rem;">
			    <button style="width: 100%;height: 40px;background-color: #ee0a24;border: 1px solid #ee0a24;border-radius: 20px;color: #FFFFFF;" @click='lineEdit'>编辑</button>
			  </div>
			</van-form>
		</van-popup>
	</div>
</template>

<script>
	import {
		lineList,
		lineAdd,
		lineDel,
		lineInfo,
		lineEdit
	} from '@/api/line'
	import {Toast,Dialog} from "vant";
	export default {
		data() {
			return {
				add: false,
				edit:false,
				goodsList: [], // 请求数据
				name:'',
				tip:'',
				oid:''
			}
		},
		methods: {
			lineList() {
				lineList().then((res) => {
					// console.log(res)
					this.goodsList = res.data.data
					// this.goodsist = res.data.data
				})
			},
			addLine() {
				this.add = true;
			},
			// 添加
			onSubmit(){
				let params = {
					name:this.name,
					tip:this.tip
				}
				lineAdd(params).then((res)=>{
					// console.log(res)
					this.add = false;
					Toast({
					  message:res.msg,
					});
					this.lineList()
				})
			},
			// 删除
			del(id){
				this.oid = id
				let params = {
					id:this.oid
				}
				lineDel(params).then((res)=>{
					// console.log(res)
					Toast({
					  message:res.msg,
					});
					this.lineList()
				})
			},
			// 详情
			detail(id){
				this.edit = true;
				this.oid = id
				let params = {
					id:this.oid
				}
				lineInfo(params).then((res)=>{
					this.name = res.data.name
					this.tip = res.data.tip
				})
			},
			// 编辑
			lineEdit(){
				// console.log(this.oid)
				let params = {
					id:this.oid,
					name:this.name,
					tip:this.tip
				}
				lineEdit(params).then((res)=>{
					// console.log(res)
					Toast({
					  message:res.msg,
					});
					this.lineList()
					this.edit = false;
				})
			},
			
			// 上拉加载请求方法
			onLoadList() {
				this.page++
				this.lineList()
			},
		},
		mounted() {
			this.lineList()
		}
	}
</script>

<style lang="less" scoped>
	.line {
		min-height: calc(100vh);
		position: relative;
		.add{
			color: #fff;
			background-color: #ee0a24;
			border: 1px solid #ee0a24;
			width: 80px;
			height: 40px;
			border-radius: 20px;
			position: absolute;
			bottom: 10px;
			left: calc(50% - 40px);
		}
		.goods-item {
			background-color: #FFFFFF;
			height: 68px;
			border-radius: 5px;
			margin-bottom: 10px;
			font-size: 14px;
			padding: 0 10px;
			.lists{
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				p{
					margin: 0;
				}
			}
		}
		.pass-form {
		  width: 18rem;
		  padding: 2rem 1rem;
		}
	}
	.van-swipe-cell{
		height: 100%;
	}
	.van-button {
		height: 100% !important;
	}
</style>
