import request from '@/utils/request'

// 排队总览
export function lineOverview() {
    return request({
        url: '/line/overview',
        method: 'get'
    })
}

// 叫号
export function call(params) {
    return request({
        url: '/line/call',
        method: 'POST',
		params:params
    })
}


// 排队列表
export function lineList() {
    return request({
        url: '/line/lst',
        method: 'get'
    })
}

// 排队添加
export function lineAdd(params) {
    return request({
        url: '/line/add',
        method: 'post',
		params:params
    })
}

// 排队编辑
export function lineEdit(params) {
    return request({
        url: '/line/edit',
        method: 'post',
		params:params
    })
}

// 详情
export function lineInfo(params) {
    return request({
        url: '/line/info',
        method: 'GET',
		params:params
    })
}

// 排队删除
export function lineDel(params) {
    return request({
        url: '/line/del',
        method: 'DELETE',
		params:params
    })
}


// 
export function info(params) {
    return request({
        url: '/info',
        method: 'GET',
		params:params
    })
}

// 编辑门店状态
export function changest(params) {
    return request({
        url: '/changest',
        method: 'POST',
		params:params
    })
}